import * as React from "react";
import { createRoot } from "react-dom/client";

import Field from "components/Form/Field";
import Heading from "components/Heading";
import Message from "components/Message";
import Button from "components/Button";

import { csrfToken } from "utils/csrfToken";

interface ILoginProps {
  email?: string;
  redirect?: string | null;
  error?: string;
}

const Login = ({ email, redirect, error }: ILoginProps) => (
  <div className="absolute left-0 top-0 w-full min-h-full p-8 flex items-center justify-center">
    <div className="w-full max-w-[500px] p-8 bg-white/5 backdrop-blur-sm">
      <Heading as="h2">Login</Heading>

      {error && <Message type="error">{error}</Message>}

      <form method="post">
        <input type="hidden" name="csrf-token" value={csrfToken()} />
        {redirect && <input type="hidden" name="redirect" value={redirect} />}
        <Field
          type="email"
          name="email"
          title="Email"
          defaultValue={email}
          placeholder="something@something.com"
          className="mb-4"
        />
        <Field type="password" name="password" title="Password" className="mb-8" />
        <Button type="submit" accented className="w-full">
          Login
        </Button>
      </form>
    </div>
  </div>
);

const container = document.getElementById("app");

if (container) {
  const params = new URLSearchParams(window.location.search);
  const email = container.dataset.email;
  const redirect = params.get("redirect");
  const error = container.dataset.error;

  createRoot(container).render(<Login email={email} redirect={redirect} error={error} />);
}
